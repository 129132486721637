// AutomationForm.tsx
import React from "react";
import { Form, ErrorMessage, FormikValues } from "formik";
import InputField from "../../components/forms/InputField";
import FormikMultipleSelectField from "../../components/formikForms/FormikMultipleSelectField";
import FormikAddToList from "../../components/formikForms/FormikAddToList";
import WeeklyAutomationSchedule from "./WeeklyAutomationSchedule";
import TextError from "../../components/forms/TextError";
import {
  FULFILLMENT_RATE_OPTIONS,
  AUTOMATION_TYPE_OPTIONS,
  AVERAGE_VIEWS_OPTIONS,
  UNITS_SOLD_OPTIONS,
  ENGAGEMENT_OPTIONS,
  FOLLOWERS_COUNT_OPTIONS_UK,
  FOLLOWERS_COUNT_OPTIONS,
  VIDEO_VIEWS_OPTIONS_UK,
  GPM_OPTIONS_UK,
  GMV_OPTIONS,
  GENDER_OPTIONS,
  AGE_OPTIONS_UK,
  AGE_OPTIONS,
  CATEGORY_OPTIONS_UK,
  CATEGORY_OPTIONS,
} from "./defaultFormData";

interface AutomationFormProps {
  values: FormikValues;
  setFieldValue: any;
  validateField: any;
  shops: any;
  group: any;
  isSubmitting: boolean;
  onReturn: () => void;
}

const AutomationForm: React.FC<AutomationFormProps> = ({
  values,
  setFieldValue,
  validateField,
  shops,
  group,
  isSubmitting,
  onReturn,
}) => {
  const format = "HH:mm";
  const timePickerStyles = {
    outline: "none", // Remove default outline
    boxShadow: "none", // Remove default shadow
    border: "1px solid #e5e7eb", // Border to match react-select
    padding: "8px", // Add some padding
    width: "86%", // Set width to 86%
    borderRadius: "4px", // Match rounded corners
    color: "gray", // Text color
    fontSize: "1rem", // Font size
    lineHeight: "1.5", // Line height
  };
  const inputStyles = {
    padding: "10px", // Add padding
    width: "86%", // Ensure it takes full width
    height: "40px", // Set a fixed height to match TimePicker
    color: "gray", // Text color
    fontSize: "1rem", // Font size to match TimePicker
    lineHeight: "1.5", // Line height to match TimePicker
  };

  return (
    <Form className="mt-6 mb-2 w-full">
      <InputField
        type="text"
        placeholder="i.e. Mystery Box New Year Sale"
        name="automation_name"
        label="Automation Name"
        size="md"
      />

      <div className="mb-6">
        <FormikMultipleSelectField
          isMultiple={false}
          name="automation_type"
          placeHolder="Select Automation Type"
          consistentLabel="Automation Type"
          options={AUTOMATION_TYPE_OPTIONS}
        />
        <ErrorMessage name={"automation_type"} component={TextError} />
      </div>

      {(values.automation_type === "Message + Product Card" ||
        values.automation_type === "Message + Target Collab" ||
        values.automation_type ===
          "Message + Target Collab + Target Collab Card") && (
        <FormikAddToList
          name="product_id"
          label="Product ID"
          placeHolder="i.e. 142735249054578"
          listItems={values.product_id}
          maxListLenght={3}
          disabled={
            values.automation_type === "Message" ||
            values.automation_type === "Message + Image"
          }
        />
      )}

      <WeeklyAutomationSchedule
        values={values}
        setFieldValue={setFieldValue}
        format={format}
        timePickerStyles={timePickerStyles}
        inputStyles={inputStyles}
        validateField={validateField}
      />
      {!group?.group && (
        <>
          <div>
            <p className="block text-sm font-medium text-black mb-2">
              Automation Filters
            </p>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-10">
              <div>
                <p className="block text-sm font-medium mb-2 creator-label-color">
                  Creators
                </p>
                <div className="flex flex-col">
                  {shops.selectedStoreRegion === "UK" ? (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="category"
                        placeHolder="Category"
                        options={CATEGORY_OPTIONS_UK}
                        label="Category"
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="category"
                        placeHolder="Category"
                        options={CATEGORY_OPTIONS}
                        label="Category"
                      />
                    </div>
                  )}
                  {shops.selectedStoreRegion === "UK" ? (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="followers"
                        placeHolder="Followers"
                        options={FOLLOWERS_COUNT_OPTIONS_UK}
                        label="Followers"
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="followers"
                        placeHolder="Followers"
                        options={FOLLOWERS_COUNT_OPTIONS}
                        label="Followers"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <p className="block text-sm font-medium mb-2 followers-label-color">
                  Followers
                </p>
                <div className="flex flex-col">
                  {shops.selectedStoreRegion === "UK" ? (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="age"
                        placeHolder="Age"
                        options={AGE_OPTIONS_UK}
                        label="Age"
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="age"
                        placeHolder="Age"
                        options={AGE_OPTIONS}
                        label="Age"
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <FormikMultipleSelectField
                      isMultiple={false}
                      name="gender"
                      placeHolder="Gender"
                      options={GENDER_OPTIONS}
                      label="Gender"
                    />
                  </div>
                </div>
              </div>

              {shops.selectedStoreRegion === "US" ? (
                <div>
                  <p className="block text-sm font-medium mb-2 performance-label-color">
                    Performance
                  </p>
                  <div className="flex flex-col">
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="gmv"
                        placeHolder="GMV"
                        options={GMV_OPTIONS}
                        label="GMV"
                      />
                    </div>

                    <div className="mb-4">
                      <FormikMultipleSelectField
                        isMultiple={false}
                        name="engagement"
                        placeHolder="Engagement Rate"
                        options={ENGAGEMENT_OPTIONS}
                        label="Engagement Rate"
                      />
                    </div>

                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="units"
                        placeHolder="Units Sold"
                        options={UNITS_SOLD_OPTIONS}
                        label="Units Sold"
                      />
                    </div>

                    <div className="mb-4">
                      <FormikMultipleSelectField
                        isMultiple={false}
                        name="views"
                        placeHolder="Average Views"
                        options={AVERAGE_VIEWS_OPTIONS}
                        label="Average Views"
                      />
                    </div>

                    <div className="mb-4">
                      <FormikMultipleSelectField
                        isMultiple={false}
                        name="fulfillment_rate"
                        placeHolder="Fulfillment Rate"
                        options={FULFILLMENT_RATE_OPTIONS}
                        label="Fulfillment Rate"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="block text-sm font-medium mb-2 performance-label-color">
                    Performance
                  </p>
                  <div className="flex flex-col">
                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="video_gpm"
                        placeHolder="GPM (GMV per 1000 views)"
                        options={GPM_OPTIONS_UK}
                        label="GPM (GMV per 1000 views)"
                      />
                    </div>

                    <div className="mb-4">
                      <FormikMultipleSelectField
                        name="video_views"
                        placeHolder="Average Video Views"
                        options={VIDEO_VIEWS_OPTIONS_UK}
                        label="Average Video Views"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="flex justify-between mb-4 w-full mt-2">
        {!group?.group && (
          <>
            <button
              className="bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
              disabled={isSubmitting}
              onClick={onReturn}
            >
              Return to Automations
            </button>
            <button
              className="automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out"
              type="submit"
            >
              Next
            </button>
          </>
        )}
      </div>
      {group?.content || <div />}
    </Form>
  );
};

export default AutomationForm;
