import React, { useState } from "react";
import { Input, Modal, Select, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearSelectedStoreId,
  createShop,
  setSelectedStoreName,
} from "../store/shops";
import TextError from "./forms/TextError";
import WelcomeModal from "./WelcomeModal";
import { clearSubscriptions } from "../store/subscriptions";
import { handleError } from "../helpers";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { LuLoader } from "react-icons/lu";

interface ShopModalProps {
  placeHolder?: string;
  isInitialShop?: boolean;
}

const ShopModal: React.FC<ShopModalProps> = ({
  placeHolder = "i.e. Beary Fun Shop",
  isInitialShop = false,
}) => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(isInitialShop);
  const [shopName, setShopName] = useState("");
  const [error, setError] = useState("");
  const shopAmount = shops.shops.length;
  const [shopRegion, setShopRegion] = useState("US");

  const regionOptions = [
    { label: "Global", value: "GLOBAL" },
    { label: "United Kingdom", value: "UK" },
    { label: "United States", value: "US" },
  ];

  const handleRegionChange = (e) => {
    setShopRegion(e);
  };

  const handleCancel = () => {
    if (!isCreating) {
      console.log("Clicked cancel button");
      setShopName("");
      setOpen(false);
    }
  };

  const handleConditionalCancel = () => {
    if (!isInitialShop) {
      handleCancel();
    }
  };

  const handleOk = async () => {
    try {

      if (!shopName.trim()) {
        setError("Shop name cannot be empty.");
        return;
      }
      if (
        shops.shops.length > 0 &&
        shops.shops.some((shop) => shop.status === "payment_pending")
      ) {
        setError(
          "You already have a shop with payment pending status, you cannot create a new shop until the subscription is completed."
        );
        return;
      }
      dispatch(clearSelectedStoreId());
      dispatch(setSelectedStoreName(shopName.trim()));
      const payload = {
        customer_id: auth.meInfo.customer_id,
        shop_name: shopName.trim(),
        shop_region: shopRegion,
      };
      await dispatch(createShop(payload));
      toast.success("Shop created Successfully");
      setShopName("");
      setOpen(shopAmount < shops.shops.length);
    } catch (error) {
      handleError(error)
      dispatch(clearSubscriptions());
      setShopName("");
      setOpen(shopAmount < shops.shops.length);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const { isCreating } = useSelector((state: any) => state.shops);

  return (
    <>
      {!isInitialShop && (
        <Button
          type="primary"
          size="large"
          className="bg-blue-600 text-base font-semibold text-white py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
          onClick={() => setOpen(true)}
        >
          <PlusOutlined />
          Create New Shop
        </Button>
      )}
      <Modal maskClosable={!isCreating} open={open} footer={null} onCancel={handleConditionalCancel}>
        <h1 className="text-2xl font-bold text-black mb-3">Create New Shop</h1>
        <h3 className="text-lg font-semibold text-black mb-2">Shop Name</h3>
        <Input
          size="large"
          placeholder={placeHolder}
          value={shopName}
          onChange={(e) => {
            setShopName(e.target.value);
            setError("");
          }}
        />
        {error && <TextError>{error}</TextError>}
        <h3 className="text-lg font-semibold text-black mb-3 mt-2">
          Shop Region
        </h3>
        <Select
          size="large" // Ant Design uses 'large' instead of 'lg'
          onChange={(value) => handleRegionChange(value)} // Ant Design passes the value directly, not the event
          placeholder="Selected Region" // Equivalent to the label
          value={shopRegion}
          style={{ minWidth: 200 }} // Optional, adjust width if needed
        >
          {regionOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
        <div
          className={`flex ${isInitialShop ? "justify-end" : "justify-between"
            } mb-4 w-full mt-8`}
        >
          {!isInitialShop && (
            <button
              className=" bg-black hover:bg-gray-900 text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:shadow-outline"
              onClick={handleCancel}
            >
              Back
            </button>
          )}

          <button
            className={`flex flex-row justify-center items-center automation-add-new-button-bg hover:bg-blue-700 text-white font-semibold py-4 px-4 space-x-3 rounded-md transition duration-300 ease-in-out ${isCreating && 'cursor-not-allowed'}`}
            onClick={handleOk}
            disabled={isCreating}
          >
            <p>
              Create Shop
            </p>
            {isCreating && <LuLoader className="animate-spin" />}
          </button>
        </div>
      </Modal>
      <WelcomeModal isInitialOpen={isInitialShop} />
    </>
  );
};

export default ShopModal;
