import React from "react";
import withAuth from "../components/withAuth";
import withShop from "../components/withShop";
import ShopModal from "../components/ShopModal";
import ShopList from "./components/ShopList";

const ShopManagement = () => {
  return (
    <div>
      <div className="flex items-center justify-between pb-6 pl-5 mb-6">
        <div className="flex-y items-center justify-center">
          <h1 className="font-bold text-blue-500 font-bold mb-1">
            Manage Shops
          </h1>
          <p className="font-normal text-slate-600">
            View and manage details of your shops
          </p>
        </div>
        <ShopModal isInitialShop={false} />
      </div>
      <ShopList />
    </div>
  );
};

export default withAuth(withShop(ShopManagement));
