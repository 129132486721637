import { useEffect, useState } from "react";
import { LuBook } from "react-icons/lu";
import { CiFilter } from "react-icons/ci";
import { HiOutlinePencil } from "react-icons/hi";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
// import {
//   MdMoreVert,
//   MdOutlineAdd,
//   MdOutlinePlayArrow,
//   MdOutlinePause,
// } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Spin } from "antd";
import CustomTable from "../../common/table";
import SearchComponent from "../../common/search";
import IconButton from "../../common/button";
import Layout from "./layout";
import CustomFilters from "../customFilter/customFilters";
import FilterFooter from "../customFilter/customFilterFooter";
// import initialFilters from "./mockData/after_group_filters.json";
import data from "./mockData/creators.json";
import {
  updateFilters,
  convertToURLSearchParams,
  getSelectedFilters,
  getSelectedColumns,
} from "./helper";
import CustomModal from "../../common/modal";
import initialColumns from "./mockData/columns.json";
import { AiOutlineClose } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getGroupFilters,
  getGroups,
  setFilterLoading,
  setGroupLoading,
} from "../store/creators";
import { FaRegSadTear } from "react-icons/fa";
import { transformApiData } from "../my-creators/helper";

const Groups = () => {
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);
  const creators = useAppSelector((state) => state.creators);
  const groupsLoading = creators.groupsLoading;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsOpenModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const paramsObject = Object.fromEntries(searchParams.entries());
  const [tableColumns, setTableColumns] = useState(initialColumns);

  const [openfiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [creatorsData] = useState(data);
  const [filters, setFilters] = useState<any>({});

  let key = `${shops.selectedStoreId}`;
  if (Object.values(paramsObject).length > 0) {
    key = `${shops.selectedStoreId}-${location.search}`;
  }

  const getShopGroups = async (dataObject: any) => {
    await dispatch(
      getGroups({
        shop_id: shops.selectedStoreId,
        filters: dataObject,
        filtersKey: location.search,
      })
    );
  };

  useEffect(() => {
    if (
      shops.selectedStoreId &&
      (creators?.groups?.[key] || [])?.length === 0 &&
      Object.keys(paramsObject).length === 0
    ) {
      getShopGroups({});
    }
  }, [
    shops.selectedStoreId,
    Object.keys(paramsObject).length,
    (creators?.groups?.[key] || [])?.length,
  ]);

  const getFilter = async () => {
    await dispatch(getGroupFilters());
  };

  useEffect(() => {
    if (Object.keys(creators.groupfilters).length === 0) {
      getFilter();
    } else {
      dispatch(setFilterLoading(false));
      setFilters(
        updateFilters(transformApiData(creators.groupfilters), paramsObject)
      );
      if ((creators?.groups?.[key] || [])?.length === 0) {
        if (Object.keys(paramsObject).length) {
          let dataObject: any = {};
          Object.keys(paramsObject).forEach((key) => {
            dataObject[key] = (paramsObject[key] || "").split(",");
          });
          getShopGroups(dataObject);
        }
      }
    }
  }, [creators.groupfilters, location.search]);

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  const groupsValues = creators?.groups?.[key] || [];

  const filtered =
    (search
      ? groupsValues.filter((group) =>
          group.group_name.toLowerCase().includes(search.toLowerCase())
        )
      : groupsValues) || [];

  useEffect(() => {
    if (groupsValues.length > 0) {
      dispatch(setGroupLoading(false));
    }
  }, [groupsValues]);

  const filtersItems = [
    {
      name: "Clear All",
      handleClick: () => {
        handleResetFilters();
      },
      icon: <AiOutlineClose color={"#1E1E1E"} />,
      buttonClass:
        "flex items-center gap-2 text-gray-700 hover:text-black bg-transparent",
      textClass: "text-black",
    },
    {
      name: "Group Filters",
      handleClick: () => {
        setOpenFiltersDrawer(true);
      },
      icon: <CiFilter color={"#1E1E1E"} />,
      badge: Object.keys(paramsObject).length,
      buttonClass: "bg-gray-200 border-solid border-1 border-black",
      textClass: "text-black",
    },
  ];

  const columns = [
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      width: 200,
      render: (text, record) => (
        <Link
          className="font-medium cursor-pointer"
          to={`/groups/${record.group_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Group Conditions",
      dataIndex: "filter_config",
      key: "filter_config",
      render: (data) => (
        <div className="relative group">
          <span className="text-blue-500 cursor-pointer">
            {Object.values(data || {}).flat().length} conditions set
          </span>
          <div className="absolute hidden group-hover:block bg-black text-white p-2 rounded z-40">
            {Object.keys(data || {}).map((key) => {
              const value = data[key];
              return (
                <p>
                  {key}: {value.join(", ")}
                </p>
              );
            })}
          </div>
        </div>
      ),
    },
    {
      title: "No. of Creators",
      dataIndex: "creator_count",
      key: "creator_count",
      render: (count) => (count ? count : "-"),
    },
    {
      title: "Group GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (gmv) => gmv ? <span className="font-medium">${gmv}</span> : '-',
    },
    {
      title: "Automation Status",
      dataIndex: "automation_status",
      key: "automation_status",
      render: (status) => (
        <span
          className={`px-3 py-1 rounded ${
            status === "Active"
              ? "bg-green-100 text-green-800"
              : status === "Stopped"
                ? "bg-red-100 text-red-800"
                : "bg-gray-100 text-gray-800"
          }`}
        >
          {status || "None"}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, record) => (
        <div className="flex space-x-2">
          <button
            className="bg-gray-200 p-1 rounded"
            onClick={() => {
              navigate(`/groups/create/?id=${record.group_id}`);
            }}
          >
            <HiOutlinePencil size={20} />
          </button>
          {/* <button className="bg-gray-200 p-1 rounded" onClick={() => {}}>
            <MdMoreVert size={20} />
          </button> */}
        </div>
      ),
    },
  ];

  const handleUpdateColumns = (updatedColumns: Column[]) => {
    const newColumns = getSelectedColumns(columns, updatedColumns);
    console.log("newColumns1321321", newColumns);
    // console.log("newColumns12321321", newColumns);
    // const values = newColumns.map((column) => {
    //   return {
    //     title: column.label,
    //     dataIndex: column.field,
    //     key: column.field,
    //   };
    // });

    setTableColumns(updatedColumns);
  };

  const handleResetFilters = () => {
    const resetFilters = Object.keys(filters).reduce(
      (acc, key) => {
        const filter = filters[key as keyof typeof filters];
        acc[key as keyof typeof filters] = {
          ...filter,
          options: filter.options?.map((option) => ({
            ...option,
            selected: false,
          })),
          value: "",
          selected: false,
        };
        return acc;
      },
      {} as typeof filters
    );

    setFilters(resetFilters);
    navigate(`/groups`, { replace: true });
  };

  console.log("groupsLoading131232", groupsLoading);

  return (
    <Layout>
      {/* <TutorialModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsOpenModalOpen(false);
        }}
      /> */}
      <CustomModal
        visible={openfiltersDrawer}
        onCancel={() => {
          setOpenFiltersDrawer(false);
        }}
        title={
          <div>
            <div className="mb-2">More Filters</div>
            <div className="border-b-2 border-gray-300 mb-5" />
          </div>
        }
        showModalFooter={false}
        width={700}
        content={
          <>
            <div className="overflow-y-auto max-h-[60vh] min-h-[40vh]">
              <CustomFilters filters={filters} setFilters={setFilters} />
            </div>
            <FilterFooter
              onClearAll={() => {
                handleResetFilters();
                setOpenFiltersDrawer(false);
                // navigate(0);
              }}
              onApplyFilters={() => {
                const selectedFilters = getSelectedFilters(filters);
                const urlParams = convertToURLSearchParams(selectedFilters);

                // Example of how to get the string representation for a URL
                navigate(`/groups?${urlParams.toString()}`, {
                  replace: true,
                });
                setOpenFiltersDrawer(false);
              }}
            />
          </>
        }
      />
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">Groups</h3>
        <div className="flex">
          {/* <div
            className="flex items-center cursor-pointer mr-2"
            onClick={() => setIsOpenModalOpen(true)}
          >
            <LuBook className="mr-1 text-gray-20" />
            <h3 className="text-l text-gray-20 mr-2">View Tutorial</h3>
          </div> */}
          <IconButton
            label="Create a group"
            onClick={() => {
              navigate("/groups/create");
            }}
            icon={<PlusOutlined />} // Add the plus icon to the left
            buttonClass="bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-md" // Tailwind classes for styling
            textClass="text-sm font-medium" // Additional text styling if needed
          />
        </div>
      </div>
      <div className="flex justify-between flex-wrap mt-5">
        <div className="mt-4">
          <SearchComponent
            search={search}
            setSearch={setSearch}
            onSearch={handleSearch}
            placeholder="Search by group name"
            inputStyle={{ width: 372, height: 48 }}
          />
        </div>

        {/* <div className="flex mt-4">
          {filtersItems.map((filter, index) => (
            <div
              className="mr-4 hover:bg-black-500 hover:border-black-500 hover:text-white transition duration-300"
              key={`filter-${filter.name}`}
            >
              <Badge count={filter.badge || 0}>
                <ColumnSelector
                  columns={tableColumns}
                  showButton={
                    filter.name === "Clear All"
                      ? Object.keys(paramsObject).length > 0
                      : true
                  }
                  onUpdate={handleUpdateColumns}
                  restProps={{
                    label: filter.name,
                    showPopOver: filter.showPopOver,
                    onClick: filter.handleClick,
                    icon: filter.icon,
                    buttonClass: filter.buttonClass,
                    textClass: filter.textClass,
                  }}
                />
              </Badge>
            </div>
          ))}
        </div> */}
      </div>
      {groupsLoading || filtered.length > 0 || groupsValues.length > 0 ? (
        groupsLoading ? (
          <div className="flex items-center justify-center h-[55vh]">
            <Spin size="large" />
          </div>
        ) : (
          <CustomTable columns={columns} data={filtered} loading={false} />
        )
      ) : (
        <div className="flex justify-center items-center flex-col mt-10">
          <FaRegSadTear size={60} className="text-gray-400 mb-4" />
          <p className="text-gray-500">No Data</p>
        </div>
      )}
    </Layout>
  );
};

export default Groups;
