import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router";
import "./pages/globals.css";
import "react-toastify/dist/ReactToastify.css";
import StoreProvider from "./pages/StoreProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthProvider from "./pages/authProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV !== 'development') console.log = () => {}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <StoreProvider>
        <AuthProvider>
          <Helmet>
            <title>Reacher</title>
            <meta name="description" content="Reacher" />
          </Helmet>
          <ToastContainer
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            position="top-right"
          />
          <RouterProvider router={router} />
        </AuthProvider>
      </StoreProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
