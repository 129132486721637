import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import withAuth from "./withAuth";
import withShop from "./withShop";
import { useAppSelector } from "../hooks";
import withAdmin from "./withAdmin";

const PlanGuard = ({ children }) => {
  const navigate = useNavigate();
  const shops = useAppSelector((state) => state.shops);

  // Logic to determine if the user has a valid plan
  const selectedShop = shops.shops.find(
    (shop) => shop.shop_id === shops.selectedStoreId
  );
  const userInactive = ["inactive", "payment_pending"].includes((selectedShop || {})?.status);

  useEffect(() => {
    if (Object.values(selectedShop).length > 0 && userInactive) {
      navigate("/subscriptions", { replace: true });
    }
  }, [userInactive]);

  return userInactive ? null : children;
};

export default withAuth(withAdmin(withShop(PlanGuard)));
