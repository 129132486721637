import React from "react";
import { Spin } from "antd";
import { useAppSelector } from "./hooks";
import Dashboard from "./dashboard";

const Page: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);

  if (!auth.meInfo.customer_id) {
    return (
      <Spin
        size="large"
        style={{
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Ensures the container takes the full viewport height.
          width: "100vw", // Ensures the container takes the full viewport width.
        }}
      />
    );
  }

  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default Page;
