import { Button, Modal, Spin } from "antd";
import { Fragment, useState } from "react";
import { BsStars } from "react-icons/bs";
import { LuRocket } from "react-icons/lu";
import { features } from "../../../utils/features";
import Feature from "../components/Feature";
import SecondModal from "./SecondModal";
import "../../../App.css";
import { plans } from "../../../utils/plans";
import { useAppSelector } from "../../../pages/hooks";

// JSON array for subscription plans
const subscriptionPlans = [
  {
    id: process.env.REACT_APP_BASIC_ID as string,
    name: "Basic",
    price: plans[process.env.REACT_APP_BASIC_ID as string].price,
    description:
      "Ideal for small businesses that are new to TikTok Shop, and looking to generate up to five figures in monthly GMV",
    features: features?.freeFeatures,
    buttonClass:
      "bg-transparent mt-6 text-base font-semibold text-black border-gray-300 border hover:bg-gray-100 py-2 rounded-md transition duration-300 ease-in-out",
  },
  {
    id: process.env.REACT_APP_PRO_ID as string,
    name: "Pro",
    price: plans[process.env.REACT_APP_PRO_ID as string].price,
    description:
      "Recommended for up-and-coming or established brands looking to scale to six or seven figures in monthly GMV.",
    features: features?.proFeatures,
    buttonClass:
      "bg-blue-600 mt-6 text-base font-semibold text-white py-2 rounded-md border-none hover:bg-blue-700 transition duration-300 ease-in-out",
    bestValue: true,
  },
];

const FirstModal = () => {
  const auth = useAppSelector((state) => state.auth);
  const [quantities, setQuantities] = useState(
    subscriptionPlans.map((plan) => ({ id: plan.id, qty: 0 }))
  );

  const handleQuantityChange = (id: string, fun: string) => {
    setQuantities((prevQuantities) =>
      prevQuantities.map((item) =>
        item.id === id
          ? {
              ...item,
              qty: fun === "inc" ? item.qty + 1 : Math.max(item.qty - 1, 0),
            }
          : item
      )
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [curr, setCurr] = useState("");

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setQuantities(subscriptionPlans.map((plan) => ({ id: plan.id, qty: 0 })));
    setIsModalOpen(false);
  };

  const handleBack = () => {
    setCurr("");
    handleCancel();
  };

  return (
    <Fragment>
      {auth?.meInfo.customer_id ? (
        <Button
          type="primary"
          onClick={() => {
            if (auth?.meInfo?.stripe_customer_id) {
              setCurr("");
              showModal();
            }
          }}
          size="large"
          className="bg-blue-600 text-base font-semibold text-white py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
          disabled={!auth?.meInfo?.stripe_customer_id}
        >
          <LuRocket className="text-lg" />{" "}
          {auth?.meInfo?.stripe_customer_id
            ? "Purchase Subscriptions"
            : "No Stripe Customer Id Linked"}
        </Button>
      ) : (
        <Spin />
      )}
      <Modal
        className={`${curr ? "custom-modal" : "custom-modal-white"} top-2 transition-none`}
        footer={null}
        title={
          curr ? null : (
            <div className="text-center text-gray-800 bg-modalbg mb-5">
              <p className="font-bold text-2xl">
                Purchase Subscription
              </p>
              <p className="font-normal font-sans leading-6">
                Add multiple plans to cart for bulk checkout
              </p>
            </div>
          )
        }
        width={"1000px"}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        {!curr ? (
          <div className="flex flex-row justify-center bg-white gap-x-10 mb-5">
            {subscriptionPlans.map((plan) => (
              <div
                key={plan.id}
                className={`relative flex flex-col ${
                  plan.bestValue
                    ? "bg-gradient-to-b from-cyan-400 to-blue-600 p-1 rounded-2xl shadow-md"
                    : "border-[#d1d5db] border rounded-xl"
                }`}
              >
                <div className="flex flex-col bg-white rounded-xl relative py-10 px-6">
                  {plan.bestValue && (
                    <div className="absolute top-0 left-40 right-0 mx-auto w-max flex items-center gap-2 px-2 py-1 bg-gradient-to-b from-cyan-400 to-blue-600 text-white rounded-b-md">
                      <BsStars />
                      <span className="text-sm">BEST VALUE</span>
                    </div>
                  )}
                  <h1 className="font-bold text-xl text-black mt-4">
                    {plan.name}
                  </h1>
                  <p className="mt-3 text-sm leading-5 text-offwhitetext">
                    {plan.description}
                  </p>
                  <div className="flex flex-row mt-5 mb-6 items-center">
                    <p className="font-semibold text-4xl text-subscriptionPriceText">
                      ${plan.price}
                    </p>
                    <p className="font-light text-base leading-5 text-dullmonthText">
                      &nbsp;/ Month
                    </p>
                  </div>
                  <p className="text-sm leading-5 text-offwhitetext">
                    Per Shop
                  </p>
                  <button
                    onClick={() => {
                      setCurr(plan.name.toLowerCase());
                      handleQuantityChange(plan.id, "inc");
                    }}
                    className={`${plan.buttonClass} mt-6 text-base font-semibold py-2 rounded-md transition duration-300 ease-in-out`}
                  >
                    Add to cart
                  </button>
                  <div className="mt-5 flex flex-col gap-y-3">
                    {plan.features.map((item, ind) => (
                      <Feature text={item} key={ind} />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <SecondModal
            handleQuantityChange={handleQuantityChange}
            quantities={quantities}
            handleCancel={handleBack}
            handleDone={handleCancel}
            curr={curr}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default FirstModal;
