import { useEffect } from "react";
import { useAppDispatch } from "./hooks";
import clientService from "./helpers/client";
import { changeLoading, getMeInfo } from "./store/auth";
import { clearState } from "./store/shops";
import { clearSubscriptions } from "./store/subscriptions";

export default function AuthProvider({ children }) {
  const dispatch = useAppDispatch();

  const getUserInfo = async () => {
    await dispatch(getMeInfo());
  };
  const getRefreshToken = async () => {
    if (window.location.pathname !== "/login") {
      clientService
        .post("/api/auth/refresh-token")
        .then((response) => {
          if (response.status === 200) {
            getUserInfo();
          } else {
            localStorage.clear();
            sessionStorage.clear();
            dispatch(clearState());
            dispatch(clearSubscriptions());
          }
        })
        .catch(() => {
          localStorage.clear();
          dispatch(clearState());
          dispatch(clearSubscriptions());
          window.location.href = "/login";
        });
    } else {
      dispatch(changeLoading(false))
      dispatch(clearState());
    }
  };

  useEffect(() => {
    getRefreshToken();
  }, []);

  return children;
}
