import React, { useState } from "react";
import { Table } from "antd";

interface DataType {
  key: string;
  [key: string]: any;
}

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: DataType) => React.ReactNode;
}

interface CustomTableProps {
  data: DataType[];
  loading?: boolean;
  columns: ColumnType[];
  tableClassName?: string;
  rowSelection?: any;
  restProps?: any;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  loading = false,
  columns,
  tableClassName = "",
  rowSelection,
  restProps = {}
}) => {
  // State to manage the page size
  const [pageSize, setPageSize] = useState<number>(10);

  // Handler for page size change
  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={{
        pageSize,
        pageSizeOptions: ['10', '20', '50', '100'], // Dropdown options for page size
        showSizeChanger: true, // Show dropdown to change page size
        onShowSizeChange: handlePageSizeChange, // Handle page size change
      }}
      className={tableClassName}
      rowSelection={rowSelection}
      {...restProps}
    />
  );
};

export default CustomTable;
