import React from "react";
import { Select } from "antd";

interface SidebarSelectorProps {
  placeHolder?: string;
  value: number;
  options: { value: number; label: string }[];
  onChange: (value?: number) => void;
}

const SidebarSelector: React.FC<SidebarSelectorProps> = ({
  placeHolder,
  value,
  options,
  onChange,
}) => {
  const handleChange = (value: string) => {
    const splitValue = value.split(/[-]+/).pop();
    const numericValue = parseInt(splitValue, 10);
    onChange(numericValue);
  };

  const isValueInOptions = options.some((option) => option.value === value);
  if (!isValueInOptions && options.length > 0) {
    const firstOptionValue = options[0].value;
    handleChange(firstOptionValue.toString());
  }
  const option = options.find(el => el.value === value)

  return (
    <div>
      <Select
        size="large" // Equivalent to size="lg"
        onChange={(value) => handleChange(value)} // Ant Design passes the value directly
        showSearch
        placeholder={placeHolder} // Equivalent to label for Ant Design
        value={`${option?.label}-${value}`.toString()}
        style={{ minWidth: 200 }} // Optional: adjust as per your design needs
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={`${option.label}-${option.value}`.toString()}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SidebarSelector;
