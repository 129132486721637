import { useState } from "react";
import { BsArrowLeft, BsStars } from "react-icons/bs";
import { toast } from "react-toastify";
import { LuLoader } from "react-icons/lu";
import { features } from "../../../utils/features";
import Feature from "../components/Feature";
import AddToCart from "../components/AddToCart";
import BillingSummary from "../components/BillingSummary";
import { plans } from "../../../utils/plans";
import clientService from "../../../pages/helpers/client";
import { Api } from "../../../pages/constants/api";
import {
  getInvoicesData,
  getSubscriptionsData,
} from "../../../pages/store/subscriptions";
import { handleError } from "../../../pages/helpers";
import { useAppDispatch } from "../../../pages/hooks";
import "./SecondModal.css";


const SecondModal = ({
  curr,
  handleDone,
  handleCancel,
  quantities,
  handleQuantityChange,
}: any) => {
  const [selected, setSelected] = useState(!curr ? "basic" : curr);
  const {
    id: b_id,
    name: b_name,
    price: b_price,
  } = plans[process.env.REACT_APP_BASIC_ID as string];
  const {
    id: p_id,
    name: p_name,
    price: p_price,
  } = plans[process.env.REACT_APP_PRO_ID as string];
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const checkout = async () => {
    try {
      setLoading(true);
      const { data } = await clientService.post(
        Api.stripePayment.createCheckoutSession,
        { items: quantities, current_domain: window.location.origin }
      );
      !data.url && handleDone();
      setLoading(false);
      !data.url &&
        dispatch(getSubscriptionsData()).then(async () => {
          dispatch(getInvoicesData());
        });
      if (data.url === null) {
        dispatch(getInvoicesData());
        toast.success("Subscriptions Updated Successfully");
      } else {
        window.location.href = data.url;
      }
    } catch (error) {
      handleError(error);
      handleDone();
      setLoading(false);
    }
  };

  return (
    <div className='max-h-[800px]'>
      <div className="flex flex-row h-full">
        {/* Tabs Side*/}
        <div className="w-96 bg-white rounded-xl h-full flex flex-col">
          <div className="flex flex-row">
            <div
              className={`w-48 cursor-pointer text-center py-5 px-6 border-b-2 text-sm font-semibold ${selected === "basic" && "text-blue-700"} ${selected === "basic" ? "border-blue-600" : ""}`}
              onClick={() => setSelected("basic")}
            >
              Basic
            </div>
            <div
              className={`w-48 cursor-pointer text-center py-5 px-6 border-b-2 text-sm font-semibold ${selected === "pro" && "text-blue-700"} ${selected === "pro" ? "border-blue-600" : ""}`}
              onClick={() => setSelected("pro")}
            >
              Pro
            </div>
          </div>
          <div>
            {selected === "basic" ? (
              <div className="flex flex-col py-10 px-6 bg-white rounded-b-xl">
                <h1 className=" font-bold text-xl text-black">Basic</h1>
                <p className="mt-3 text-sm leading-5 text-offwhitetext">
                  Ideal for small businesses that are new to TikTok Shop, and
                  looking to generate up to five figures in monthly GMV
                </p>
                <div className="flex flex-row mt-5 mb-6 items-center">
                  <p className="font-semibold text-6xl text-subscriptionPriceText">
                    ${b_price}
                  </p>
                  <p className="font-light text-base leading-5 text-dullmonthText">
                    &nbsp;/ Month
                  </p>
                </div>
                <p className="text-sm leading-5 text-offwhitetext">Per Shop</p>
                {quantities.find((item: any) => item.id === b_id)?.qty <= 0 ? (
                  <button
                    onClick={() => handleQuantityChange(b_id, "inc")}
                    className="bg-transparent mt-6 text-base font-semibold text-black border-gray-300 border hover:bg-gray-100 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    Add to cart
                  </button>
                ) : null}
                <div className="mt-5 flex flex-col gap-y-3">
                  {features?.freeFeatures?.map((item, ind) => (
                    <Feature text={item} key={ind} />
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col py-10 px-6">
                <div className="flex flex-row justify-between">
                  <h1 className=" font-bold text-xl text-black">Pro</h1>
                  <div className="bg-gradient-to-b from-cyan-400 to-blue-600 flex flex-row left-52 items-center px-2 gap-1 text-white rounded-md">
                    <BsStars />
                    <p className="text-sm">BEST VALUE</p>
                  </div>
                </div>
                <p className="mt-3 text-sm leading-5 text-offwhitetext">
                  Recommended for up-and-coming or established brands looking to
                  scale to six or seven figures in monthly GMV.
                </p>
                <div className="flex flex-row mt-5 mb-6 items-center">
                  <p className="font-semibold text-6xl text-subscriptionPriceText">
                    ${p_price}
                  </p>
                  <p className="font-light text-base leading-5 text-dullmonthText">
                    &nbsp;/ Month
                  </p>
                </div>
                <p className="text-sm leading-5 text-offwhitetext">Per Shop</p>
                {quantities.find((item: any) => item.id === p_id)?.qty <= 0 ? (
                  <button
                    onClick={() => handleQuantityChange(p_id, "inc")}
                    className="bg-blue-600 mt-6 text-base font-semibold text-white py-2 rounded-md border-none hover:bg-blue-700 transition duration-300 ease-in-out"
                  >
                    Add to cart
                  </button>
                ) : null}
                <div className="mt-5 flex flex-col gap-y-3">
                  {features?.proFeatures?.map((item, ind) => (
                    <Feature text={item} key={ind} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Cart Side */}
        <div className="mx-10 flex flex-col w-full py-2">
          <div className="flex flex-row gap-x-4 items-center">
            <BsArrowLeft
              onClick={!loading && handleCancel}
              className="text-lg cursor-pointer me-2"
            />
            <h1 className="font-bold text-3xl">Cart</h1>
          </div>
          <div className="mt-8">
            {quantities.find((item: any) => item.id === b_id)?.qty ? (
              <AddToCart
                name={b_name}
                id={b_id}
                handleQuantityChange={handleQuantityChange}
                quantities={quantities}
                price={b_price}
              />
            ) : null}
            {quantities.find((item: any) => item.id === p_id)?.qty ? (
              <AddToCart
                name={p_name}
                id={p_id}
                handleQuantityChange={handleQuantityChange}
                quantities={quantities}
                price={p_price}
              />
            ) : null}
          </div>
          <div>
            <BillingSummary quantities={quantities} />
          </div>
          <div className="flex flex-col justify-end h-full">
            <p className="text-xs font-medium text-slate-600">
              * Start with a 7-day free trial on any plan—no charge upfront, and
              we'll only bill your card after the trial ends. You're in control
            </p>
          </div>
          <button
            className={`items-center flex justify-center bg-blue-600 mt-6 text-base font-semibold mb-3 text-white py-2 rounded-md border-none hover:bg-blue-700 transition duration-300 ease-in-out ${loading && "cursor-not-allowed"}`}
            disabled={loading}
            onClick={checkout}
          >
            Proceed to checkout
            {loading && <LuLoader className="animate-spin ml-2" />}
          </button>
          <p className="text-gray-500 text-xs text-center">Powered by stripe</p>
        </div>
      </div>
    </div>
  );
};

export default SecondModal;
