import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  useNavigate,
  // useSearchParams,
  useParams,
  // useLocation,
} from "react-router-dom";
import { Tag, Select, Badge, Spin } from "antd";
import { IoChevronBack } from "react-icons/io5";
import CustomTable from "../../common/table";
import IconButton from "../../common/button";
import Layout from "./layout";
import initialFilters from "./mockData/after_group_filters.json";
import data from "./mockData/singleColumn.json";
import {
  convertToURLSearchParams,
  getSelectedFilters,
  updateFilters,
} from "./helper";
import CustomModal from "../../common/modal";
import { LuTrash2 } from "react-icons/lu";
import { HiOutlinePencil } from "react-icons/hi2";
import AutomationOverview from "./automation";
import CustomFilters from "../customFilter/customFilters";
import FilterFooter from "../customFilter/customFilterFooter";
import { CiFilter } from "react-icons/ci";
import { Collapse } from "antd";
import Panel from "antd/es/splitter/Panel";
import type { CollapseProps } from "antd";
import {
  deleteGroup,
  getGroups,
  getMyCreatorsByGroup,
  setGroupLoading,
} from "../store/creators";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getStatusColor } from "../my-creators/helper";

const { Option } = Select;

// Define the type for each detail entry
// interface DetailEntry {
//   title: string;
//   value: string | number;
// }

const SingleGroup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const paramsObject = { id };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isAddTagModalVisible, setIsAddTagModalVisible] = useState(false);
  const tags = [
    { value: "tag1", label: "Tag 1", selected: false },
    { value: "tag2", label: "Tag 2", selected: false },
    { value: "tag3", label: "Tag 3", selected: false },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openfiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const groupLoading = creators.groupsLoading;
  const [creatorsData] = useState(data[id]);
  const [filters, setFilters] = useState<any>(
    updateFilters(initialFilters, paramsObject)
  );
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("Selected row keys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleMultiSelectChange = (values: string[]) => {
    setSelectedTags(values);
  };

  // const filtersItems = [
  //   {
  //     name: "Group Filters",
  //     handleClick: () => {
  //       setOpenFiltersDrawer(true);
  //     },
  //     icon: <CiFilter color={"#1E1E1E"} />,
  //     badge: Object.keys(paramsObject).length,
  //   },
  // ];

  console.log(
    " paramsObject?.id && !creators?.singleGroupCreators?.[paramsObject?.id]",
    creators?.singleGroupCreators
  );
  useEffect(() => {
    if (
      paramsObject?.id &&
      !creators?.singleGroupCreators?.[paramsObject?.id]
    ) {
      dispatch(getMyCreatorsByGroup({ group_id: paramsObject?.id }));
    }
  }, [paramsObject?.id]);

  useEffect(() => {
    if (creators?.singleGroupCreators?.[paramsObject?.id]) {
      dispatch(setGroupLoading(false));
    }
  }, [creators?.singleGroupCreators?.[paramsObject?.id]]);

  const fallbackImage = "/logo192.png";

  const columns = [
    {
      title: "TikTok Handle",
      dataIndex: "creator_name",
      key: "creator_name",
      render: (
        creatorName: string,
        record: { avatar_url: string; follower_num: any }
      ) => (
        <div className="flex items-center">
          <img
            src={record?.avatar_url || fallbackImage}
            alt={creatorName}
            className="w-8 h-8 rounded-full mr-2"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevent infinite loop in case fallback image fails
              e.currentTarget.src = fallbackImage; // Replace with fallback image
            }}
          />
          <div>
            <div>{creatorName}</div>
            <div className="text-gray-500">
              Followers: {record.follower_num}
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   key: "tags",
    //   render: (tags) =>
    //     tags ? (
    //       <div className="d-flex flex-wrap">
    //         {tags.map((tag, index) => (
    //           <Tag
    //             key={index}
    //             className="flex bg-gray-200 text-black px-2 py-1 rounded space-between items-center mb-2"
    //           >
    //             {tag}
    //           </Tag>
    //         ))}
    //       </div>
    //     ) : (
    //       <span className="text-gray-500">No Tags</span>
    //     ),
    // },
    {
      title: "Status",
      dataIndex: "curr_status",
      key: "curr_status",
      render: (status: string) => (
        <span
          className={"px-5 py-2 rounded-md border border-stroke"}
          style={{
            color: getStatusColor(status),
            backgroundColor: `${getStatusColor(status)}23`,
            borderColor: `${getStatusColor(status)}80`,
          }}
        >
          {status ? status : "-"}
        </span>
      ),
    },
    {
      title: "GMV",
      dataIndex: "gmv",
      key: "gmv",
      render: (gmv) => <span className="font-medium">${gmv.toFixed(2)}</span>,
    },
    {
      title: "Date Entered Group",
      dataIndex: "added_date",
      key: "added_date",
      render: (date) => <span>{new Date(date).toLocaleDateString()}</span>,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCancel = () => {
    setIsAddTagModalVisible(false);
  };

  const handleConfirm = () => {
    console.log("Tags added:", tags);
    // Add your tag addition logic here
    setIsAddTagModalVisible(false);
  };

  const handleDelete = async () => {
    const userConfirmed = window.confirm(
      "Caution: Proceeding may cause loss of unsaved data. Do you want to continue?"
    );
    if (userConfirmed) {
      const result = await dispatch(
        deleteGroup({
          id,
          data: {
            group_id: id,
            shop_id: shops.selectedStoreId,
          },
        })
      );
      if (deleteGroup.fulfilled.match(result)) {
        await dispatch(
          getGroups({
            shop_id: shops.selectedStoreId,
          })
        );
      } 
      navigate("/groups", { replace: true });
    }
  };
  // console.log("creators111", creators.singleGroupCreators)

  const automation = true;
  const automationOverview = [
    {
      status: true,
      types: [
        {
          title: "Message type:",
          value: "Message + Target collaboration",
        },
        { title: "Creators reached:", value: 8 },
        { title: "Creators remaining:", value: 4 },
        { title: "Replies:", value: 6 },
        { title: "Date created:", value: "2024-09-09" },
      ],
    },
    {
      status: true,
      types: [
        {
          title: "Message type:",
          value: "Message + Target collaboration",
        },
        { title: "Creators reached:", value: 1 },
        { title: "Creators remaining:", value: 3 },
        { title: "Replies:", value: 4 },
        { title: "Date created:", value: "2024-10-09" },
      ],
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: `Automation Overview (${automationOverview?.length})`,
      children: (automationOverview || []).map((automation, index) => (
        <AutomationOverview
          automationName="Automation Name"
          onClick={() => {
            navigate("/automations");
          }}
          details={automation.types}
          key={`creators-automation-${index}`}
        />
      )),
    },
  ];

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  console.log(
    "creators?.singleGroupCreators?.[paramsObject?.id]",
    creators?.singleGroupCreators
  );

  return (
    <Layout>
      <CustomModal
        visible={openfiltersDrawer}
        onCancel={() => {
          setOpenFiltersDrawer(false);
        }}
        title={
          <div>
            <div className="mb-2">More Filters</div>
            <div className="border-b-2 border-gray-300 mb-5" />
          </div>
        }
        showModalFooter={false}
        width={700}
        content={
          <>
            <div className="overflow-y-auto max-h-[60vh] min-h-[40vh]">
              <CustomFilters filters={filters} setFilters={setFilters} />
            </div>

            <FilterFooter
              onClearAll={() => {
                setFilters(filters);
                navigate(`/groups/${id}`, { replace: true });
                navigate(0);
              }}
              onApplyFilters={() => {
                const selectedFilters = getSelectedFilters(filters);
                const urlParams = convertToURLSearchParams(selectedFilters);

                // Example of how to get the string representation for a URL
                navigate(`/groups/${id}?${urlParams.toString()}`, {
                  replace: true,
                });
                setOpenFiltersDrawer(false);
              }}
            />
          </>
        }
      />
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <IoChevronBack
            className="text-xl text-gray-600 cursor-pointer"
            onClick={() => {
              navigate("/groups");
            }}
          />
          <h3 className="font-semibold text-xl">Groups</h3>
        </div>
        <div className="flex">
          <div className="flex space-x-4 mr-4">
            {/* Edit Icon Button */}
            <button
              className="bg-gray-200 p-2 rounded border border-gray-300"
              onClick={() => {
                navigate(`/groups/create?id=${id}`);
              }}
            >
              <HiOutlinePencil size={20} />
            </button>

            {/* Delete Icon Button */}
            <button
              className="bg-gray-200 p-2 rounded border border-gray-300"
              onClick={() => {
                handleDelete();
              }}
            >
              <LuTrash2 size={20} />
            </button>
          </div>

          <IconButton
            label={`Create ${automation ? "new" : ""} automation`}
            onClick={() => {
              navigate("/automations?type=create");
            }}
            icon={<PlusOutlined />} // Add the plus icon to the left
            buttonClass="bg-black text-white px-4 py-2 rounded-md" // Tailwind classes for styling
            textClass="text-sm font-medium" // Additional text styling if needed
            style={{ height: 40 }}
            restProps={{ disabled: true }}
          />
        </div>
      </div>
      {/* {automation && (
        <div className="mt-10">
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
            expandIconPosition={"end"}
          />
        </div>
      )} */}

      <div className="mt-4 mb-8">
        <CustomModal
          visible={isAddTagModalVisible}
          showModalFooter
          title="Batch add tags"
          content={
            <div>
              <p className="text-gray-500">
                Only 3 tags can be added per creator.
              </p>
              <Select
                mode="multiple"
                value={tags
                  ?.filter((option) => selectedTags.includes(option.value))
                  .map((option) => option.value)}
                onChange={(values) =>
                  handleMultiSelectChange(values as string[])
                }
                className="w-full mt-2"
                placeholder="Select tags"
              >
                {tags?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          }
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          confirmText="Add tags"
          cancelText="Cancel"
          confirmButtonType="primary"
        />
      </div>
      {groupLoading || !creators?.singleGroupCreators?.[paramsObject?.id] ? (
        <div className="flex items-center justify-center h-[55vh]">
          <Spin size="large" />
        </div>
      ) : (
        <CustomTable
          columns={columns}
          data={creators?.singleGroupCreators?.[paramsObject?.id]}
          loading={false}
          // rowSelection={rowSelection}
        />
      )}
    </Layout>
  );
};

export default SingleGroup;
