export const updateFilters = (filters, paramsObject) => {
  // Iterate over each key in the paramsObject
  Object.entries(paramsObject).forEach(([key, values]) => {
    // Split the values by commas to get an array of selected values
    const selectedValues = values.split(",");

    // Check if the filter exists in the filters object
    if (filters[key] && filters[key].options) {
      // Update the selected status of each option based on the values from paramsObject
      if (filters[key].extra) {
        const updatedSelectedValues = filters[key].extra
          .filter((el) => selectedValues.includes(el[filters[key].fetchKeyName]))
          .map((data) => `${data.product_title}-${data.product_id}`);
        filters[key].options = filters[key].options.map((option) => ({
          ...option,
          selected: updatedSelectedValues.includes(option.value),
        }));
        console.log("filters123123", filters)
      } else {
        filters[key].options = filters[key].options.map((option) => ({
          ...option,
          selected: selectedValues.includes(option.value),
        }));
      }
    }
  });

  return filters; // Return the updated filters object
};

export const convertToURLSearchParams = (filters) => {
  const params = new URLSearchParams();
  Object.entries(filters).forEach(([key, values]) => {
    if (values.length) {
      params.append(key, values.join());
    }
  });

  return params;
};

export const getSelectedFilters = (filters) => {
  const selectedFilters: { [key: string]: any } = {};

  Object.entries(filters).forEach(([key, filter]) => {
    if (filter.extra) {
      const selectedOptions = filter.options
        .filter((option) => option.selected)
        .map((option) => option.value.split(/[-]+/).pop());
      if (selectedOptions.length > 0) {
        selectedFilters[key] = selectedOptions;
      }
    } else {
      if (filter.type === "text") {
        if (filter.value) {
          selectedFilters[key] = filter.value;
        }
      } else if (filter.options) {
        const selectedOptions = filter.options
          .filter((option) => option.selected)
          .map((option) => option.value);
        if (selectedOptions.length > 0) {
          selectedFilters[key] = selectedOptions;
        }
      }
    }
  });

  return selectedFilters;
};

export const updateTags = (data, key, tag, action) => {
  const item = data.find((selectedRow) => selectedRow.key === key);
  if (!item) {
    console.log("Item not found");
    return;
  }
  if (action === "add") {
    if (!item.tags.includes(tag)) {
      item.tags.push(tag);
      console.log(`Tag '${tag}' added to key ${key}`);
    } else {
      console.log(`Tag '${tag}' already exists in key ${key}`);
    }
  } else if (action === "remove") {
    const tagIndex = item.tags.indexOf(tag);
    if (tagIndex !== -1) {
      item.tags.splice(tagIndex, 1);
      console.log(`Tag '${tag}' removed from key ${key}`);
    } else {
      console.log(`Tag '${tag}' not found in key ${key}`);
    }
  } else {
    console.log("Invalid action. Use 'add' or 'remove'.");
  }
  return data;
};

export const getSelectedColumns = (columns, columnConfig) => {
  // Extract fields of selected columns from configuration
  const selectedFields = columnConfig.filter((config) => config.selected);

  // Filter columns based on selected fields
  const filteredFields = selectedFields.filter((field) =>
    columns.every((column) => column.key !== field.field)
  );
  return filteredFields.sort((a, b) => a.order - b.order);
};

export function transformApiData(apiData: any) {
  const createCheckboxOptions = (labels: any) =>
    labels.map((label: any) => ({
      label,
      value: label,
      selected: false,
    }));

  const transformedData = Object.values(apiData).length
    ? {
        status: {
          label: labels.status,
          type: "checkbox",
          options: createCheckboxOptions(apiData.status),
        },
        followerCount: {
          label: labels.followerCount,
          type: "checkbox",
          options: createCheckboxOptions(apiData.followerCount),
        },
        totalGMV: {
          label: labels.totalGMV,
          type: "checkbox",
          options: createCheckboxOptions(apiData.totalGMV),
        },
        shopGMV: {
          label: labels.shopGMV,
          type: "checkbox",
          options: createCheckboxOptions(apiData.shopGMV),
        },
        creatorFulfillmentRate: {
          label: labels.creatorFulfillmentRate,
          type: "checkbox",
          options: createCheckboxOptions(apiData.creatorFulfillmentRate),
        },
        products: {
          label: labels.products,
          type: "dropdown_multiselect",
          options: createCheckboxOptions(
            apiData.products.map(
              (product: any) => `${product.product_title}-${product.product_id}`
            )
          ),
          extra: apiData.products,
          fetchKeyName: "product_id",
          KeyName: "product_title",
        },
        gender: {
          label: labels.gender,
          type: "checkbox-single",
          options: createCheckboxOptions(apiData.gender),
        },
        tags: {
          label: labels.tags,
          type: "dropdown_multiselect",
          options: createCheckboxOptions(apiData.tags),
        },
      }
    : {};

  return transformedData;
}

export const labels: any = {
  status: "Status",
  followerCount: "Follower Count",
  totalGMV: "Total GMV",
  shopGMV: "Shop GMV",
  creatorFulfillmentRate: "Creator Fulfillment Rate (CFR)",
  products: "Products",
  gender: "Gender",
  tags: "Tags",
};
export const compareValues = (obj1, obj2) => {
  // Function to normalize the value to an array
  const normalizeValue = (value) => {
    // If the value is a string, split by commas and trim whitespace
    if (typeof value === "string") {
      return value.split(",").map((item) => item.trim());
    }
    // If the value is already an array, return it as is
    if (Array.isArray(value)) {
      return value;
    }
    // Return the value as is if it's neither a string nor an array
    return value;
  };

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key-value pair
  for (const key of keys1) {
    // Normalize both values for comparison
    const value1 = normalizeValue(obj1[key]);
    const value2 = normalizeValue(obj2[key]);

    // Check if both values are arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      // Compare arrays by checking length and each item
      if (
        value1.length !== value2.length ||
        !value1.every((val) => value2.includes(val))
      ) {
        return false;
      }
    } else if (value1 !== value2) {
      // Compare primitive values directly
      return false;
    }
  }

  return true;
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase().trim()) {
    case "messaged":
      return "#2547D0";
    case "sample requested":
    case "replied":
      return "#067647";
    case "showcasing product":
      return "#4A1FB8";
    case "ready to ship":
      return "#854A0E";
    case "sample shipped":
      return "#125D56";
    case "content pending":
      return "#97180C";
    case "posted videos":
      return "#155B75";
    case "completed":
    case "sold products":
      return "#326212";
    default:
      return "gray"; // Default color if status doesn't match any case
  }
};

const toTitleCase = (str: string) => {
  return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

export function createFieldJson(existingKeys: any, sampleValue: any) {
  if (!sampleValue) {
    return;
  }
  return Object.keys(sampleValue).map((key) => {
    return {
      label: toTitleCase(key),
      field: key,
      selected: existingKeys.includes(key),
    };
  });
}
