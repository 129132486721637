import React, { useState } from "react";
import { Button, Steps } from "antd";
import CustomModal from "../../common/modal";
import { useAppSelector } from "../hooks";
import ShowGroupDetail from "./showGroupDetail";
import { ApiTwoTone, MessageTwoTone } from "@ant-design/icons";
import {
  DEFAULT_CREATOR_DM_MESSAGE,
  defaultFormData,
  message_and_taget_collab_description,
  new_automation_description,
  validationSchema as validationSchemaStepTarget,
  validationSchemaMessage,
  validationSchemaStepTwo,
} from "../automations/components/defaultFormData";
import { Formik, FormikValues } from "formik";
import getValidationSchema from "../automations/components/validationSchemaStepOne";
import AutomationForm from "../automations/components/AutomationForm";
import TargetCollabFullForm from "../automations/components/TargetCollabFullForm";
import MessageImageForm from "../automations/components/MessageImageForm";
import StepOneTwoForm from "../automations/components/StepOneTwoForm";

const AutomationModal: React.FC = (props: any) => {
  const { visible, setVisible, groupId } = props;
  const creators = useAppSelector((state) => state.creators);
  const shops = useAppSelector((state) => state.shops);
  const validationSchemaStep1 = getValidationSchema(shops.selectedStoreRegion);
  const data = creators?.singleGroup?.[groupId];

  // Step state to track the current step
  const [step, setStep] = useState(1);

  // Initialize formData state
  const [formData, setFormData] = useState<FormikValues>(defaultFormData); // Use default form data

  const handleCancel = () => {
    setStep(1);
    setVisible(false);
    setFormData(defaultFormData);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const steps = [
    {
      title: <span style={{ fontWeight: "bold" }}>New Automation</span>,
      description: new_automation_description,
      icon: <ApiTwoTone />,
    },
    {
      title: (
        <span style={{ fontWeight: "bold" }}>Message & Target Collab</span>
      ),
      description: message_and_taget_collab_description,
      icon: <MessageTwoTone />,
    },
  ];

  const handleNext = (newData: any) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
    if (step === 2) {
      handleCancel();
    } else {
      setStep(step + 1);
    }
  };

  const initializeCommissionRates = (values: FormikValues) => {
    if (
      values.product_id &&
      values.target_collab_product_comission_rates.length === 1 &&
      !values.target_collab_product_comission_rates[0].product_id
    ) {
      return values.product_id.map((productId) => ({
        product_id: productId,
        commission_rate: "",
      }));
    }
    return values.target_collab_product_comission_rates;
  };

  const handleNextwithCommissionRates = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setFormData((prevData) => ({
      ...prevData,
      target_collab_product_comission_rates: initializeCommissionRates(values),
    }));
    setStep(step + 1);
  };

  const renderStepForm = ({ content, contentStep2 }) => {
    switch (step) {
      case 1:
        return (
          <Formik
            initialValues={formData}
            validationSchema={validationSchemaStep1}
            onSubmit={(values) => handleNextwithCommissionRates(values)}
            enableReinitialize
          >
            {({ isSubmitting, values, setFieldValue, validateField }) => (
              <AutomationForm
                values={values}
                setFieldValue={setFieldValue}
                validateField={validateField}
                shops={shops}
                group={{ group: data, content }}
                isSubmitting={isSubmitting}
                onReturn={() => setVisible(false)}
              />
            )}
          </Formik>
        );
      case 2:
        if (
          formData.automation_type === "Message + Target Collab" ||
          formData.automation_type ===
            "Message + Target Collab + Target Collab Card"
        ) {
          return (
            <Formik
              initialValues={{
                ...formData,
                tc_dm_message:
                  formData.tc_dm_message || DEFAULT_CREATOR_DM_MESSAGE,
              }}
              validationSchema={validationSchemaStepTarget}
              onSubmit={(values) => handleNextwithCommissionRates(values)}
              enableReinitialize
            >
              {({ isSubmitting, values }) => (
                <TargetCollabFullForm
                  values={values}
                  shops={shops}
                  automationType={formData.automation_type}
                  onPrev={handlePrev}
                  group={{ group: data, content: contentStep2 }}
                  isSubmitting={isSubmitting}
                />
              )}
            </Formik>
          );
        } else if (formData.automation_type === "Message + Image") {
          return (
            <Formik
              initialValues={formData}
              validationSchema={validationSchemaMessage}
              onSubmit={(values) => handleNext(values)}
              enableReinitialize
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <MessageImageForm
                  setFieldValue={setFieldValue}
                  values={values}
                  onPrev={handlePrev}
                  group={{ group: data, content: contentStep2 }}
                  isSubmitting={isSubmitting}
                />
              )}
            </Formik>
          );
        } else {
          return (
            <Formik
              initialValues={formData}
              validationSchema={validationSchemaStepTwo}
              onSubmit={(values) => handleNext(values)}
              enableReinitialize
            >
              {() => (
                <StepOneTwoForm
                  onPrev={handlePrev}
                  group={{ group: data, content: contentStep2 }}
                />
              )}
            </Formik>
          );
        }
      default:
        return null;
    }
  };

  const content = (
    <div className="flex justify-end gap-4 mt-8">
      <Button onClick={handleCancel} type="link" className="text-gray-600">
        Cancel
      </Button>
      <button className="bg-blue-600 text-white px-5 rounded-lg" type="submit">
        Continue
      </button>
    </div>
  );

  const contentStep2 = (
    <div className="flex justify-between gap-4 mt-8">
      <div>
        <Button
          onClick={() => setStep(1)}
          className="bg-gray-100 border border-gray-300"
        >
          Previous
        </Button>
      </div>
      <div className="flex">
        <Button onClick={handleCancel} type="link" className="text-gray-600">
          Cancel
        </Button>
        <button
          className="bg-blue-600 text-white px-5 rounded-lg"
          type="submit"
        >
          Save & Exit
        </button>
      </div>
    </div>
  );

  const automationContent = (
    <div className="max-h-[80vh] mb-10">
      <Steps current={step - 1} items={steps} className="mb-6 mt-6" />
      <div className="overflow-y-scroll max-h-[70vh]">
        <ShowGroupDetail {...data} />
        {renderStepForm({ content, contentStep2 })}
      </div>
    </div>
  );

  return (
    <CustomModal
      visible={visible}
      title="Configure Automation"
      content={automationContent}
      onCancel={handleCancel}
      confirmText="Save & Exit"
      cancelText="Cancel"
      width={"64vw"}
    />
  );
};

export default AutomationModal;
