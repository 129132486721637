import React from "react";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import MessageImageForm from "./MessageImageForm";

interface AddNewAutomationStepMessageImageProps {
  onNext: (values: FormikValues) => void;
  onPrev: () => void;
  initialValues: FormikValues;
  group: any;
}

const validationSchema = Yup.object().shape({
  message_entry: Yup.string().required("Message Entry is required"),
});

const AddNewAutomationStepMessageImage: React.FC<AddNewAutomationStepMessageImageProps> = ({
  onNext,
  onPrev,
  initialValues,
  group
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
      enableReinitialize
    >
      {({ setFieldValue, values, isSubmitting }) => (
        <MessageImageForm
          setFieldValue={setFieldValue}
          values={values}
          onPrev={onPrev}
          group={group}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  );
};

export default AddNewAutomationStepMessageImage;
