import React from "react";
import { AiOutlineShop } from "react-icons/ai";
import { capitalizeWords, getStatusColor, labels } from "../my-creators/helper";

interface GroupDataProps {
  shop_id: number;
  group_name: string;
  filter_config: {
    status: string[];
  };
  creator_count: number;
  gmv: number;
  group_tags: string[] | null;
  follower_count?: string; // Assuming you have follower_count now
}

const CustomGroupComponent: React.FC<GroupDataProps> = ({
  group_name,
  filter_config,
  creator_count,
  gmv,
  group_tags,
}) => {
  console.log("group_tags123123", group_tags);
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md">
      <div className="justify-between items-start">
        {/* Shop and Group Info */}
        <h3 className="text-lg font-bold text-gray-800 flex items-center mb-2 ">
          <AiOutlineShop className="mr-2 text-gray-700" />
          {group_name}
        </h3>
        <div className="flex flex-wrap">
          {/* Creator Count */}
          <div className="border rounded-lg p-4 bg-white mr-2 w-[240px]  mt-2">
            <p className="text-sm text-gray-500">Creator Count:</p>
            <p className="text-xl font-bold text-gray-900 mt-4">
              {creator_count}
            </p>
          </div>

          {/* GMV */}
          <div className="border rounded-lg p-4 bg-white mr-2 w-[240px]  mt-2">
            <p className="text-sm text-gray-500">GMV:</p>
            <p className="text-xl font-bold text-gray-900 mt-4">
              ${gmv.toLocaleString()}
            </p>
          </div>

          {/* Status */}
          {Object.keys(filter_config).map((configKey, index) => (
            <div
              className="border rounded-lg p-4 bg-white mr-2 w-[240px]  mt-2"
              key={`filter-config-${index}`}
            >
              <p className="text-sm text-gray-500">{labels[configKey]}:</p>

              {filter_config[configKey].map((value) => (
                <p
                  className="inline-block px-3 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-md mt-4 mr-2"
                  style={{
                    color: getStatusColor(
                      configKey === "status" ? value : "messaged"
                    ),
                    backgroundColor: `${getStatusColor(
                      configKey === "status" ? value : "messaged"
                    )}23`,
                    borderColor: `${getStatusColor(
                      configKey === "status" ? value : "messaged"
                    )}80`,
                  }}
                >
                  {value}
                </p>
              ))}
            </div>
          ))}
        </div>
        {/* Group Tags */}
        {group_tags && (
          <div className="mt-4">
            <h4 className="font-semibold">Group Tags:</h4>
            <div className="mt-2">
              {group_tags && group_tags.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {group_tags.map((tag) => (
                    <span
                      key={tag}
                      className="bg-gray-100 text-gray-800 text-sm font-semibold px-2.5 py-1 rounded"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              ) : (
                <span className="bg-gray-100 text-gray-500 text-sm font-semibold px-2.5 py-1 rounded">
                  No Group Tags
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomGroupComponent;
