import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clientService from '../helpers/client';
import { Api } from '../constants/api';

interface Subscriptions {
    subscriptions_data: [];
    invoices_data: [];
    loadCompleted: boolean;
    cancelLoad: boolean;
    linkLoad: boolean;
    isTrailAvailed: boolean;
}

const initialState: Subscriptions = {
    subscriptions_data: [],
    invoices_data: [],
    loadCompleted: false,
    cancelLoad: false,
    linkLoad: false,
    isTrailAvailed: false
};

export const getSubscriptionsData = createAsyncThunk(
    'subscriptions/getSubscriptions',
    async () => {
        const { data } = await clientService.get(Api.subscriptions.list);
        return data;
    }
)
export const getInvoicesData = createAsyncThunk(
    'invoices/list',
    async () => {
        const { data } =  await clientService.get(Api.invoices.list);
        return data;
    }
)

export const cancelSubscription = createAsyncThunk(
    'subscriptions/cancelsubscription',
    async (sub_id) => {
        const { data } = await clientService.post(Api.subscriptions.cancel, { subscription_id: sub_id });
        return data;
    }
)

export const linkSubscription = createAsyncThunk(
    'subscriptions/link-shop',
    async ({ selectedSubscriptionLink, selectedShop }) => {
        const { data } = await clientService.post(Api.subscriptions.link, { subscription_id: selectedSubscriptionLink, shop_id: selectedShop });
        return data;
    }
)

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        clearSubscriptions: (state) => {
            state.subscriptions_data = [];
            state.invoices_data = [];
            state.loadCompleted = false;
            state.cancelLoad = false;
            state.linkLoad = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionsData.pending, (state) => {
                state.loadCompleted = false;
            })
            .addCase(getSubscriptionsData.fulfilled, (state, action) => {
                state.subscriptions_data = action.payload.subscriptions;
                state.isTrailAvailed = action.payload.isTrailAvailed;
                state.loadCompleted = true;
            })
            .addCase(getSubscriptionsData.rejected, (state) => {
                state.loadCompleted = true;
            })
            .addCase(cancelSubscription.pending, (state) => {
                state.cancelLoad = true;
            })
            .addCase(cancelSubscription.fulfilled, (state, action) => {
                state.cancelLoad = false;
            })
            .addCase(cancelSubscription.rejected, (state) => {
                state.cancelLoad = false;
            })
            .addCase(linkSubscription.pending, (state) => {
                state.linkLoad = true;
            })
            .addCase(linkSubscription.fulfilled, (state, action) => {
                state.linkLoad = false;
            })
            .addCase(linkSubscription.rejected, (state) => {
                state.linkLoad = false;
            })
            .addCase(getInvoicesData.pending, (state) => {
                state.loadCompleted = false;
            })
            .addCase(getInvoicesData.fulfilled, (state, action: any) => {
                state.invoices_data = action.payload.invoices;
                state.isTrailAvailed = action.payload.is_trial_availed;
                state.loadCompleted = true;
            })
            .addCase(getInvoicesData.rejected, (state) => {
                state.loadCompleted = true;
            })
    },
});


export const { clearSubscriptions } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;

