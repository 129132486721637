import React from "react";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import StepOneTwoForm from "./StepOneTwoForm";

interface AddNewAutomationStepOneTwoProps {
  onNext: (values: FormikValues) => void;
  onPrev: () => void;
  initialValues: FormikValues;
  group: any;
}

const validationSchema = Yup.object().shape({
  message_entry: Yup.string().required("Message Entry is required"),
});

const AddNewAutomationStepOneTwo: React.FC<AddNewAutomationStepOneTwoProps> = ({
  onNext,
  onPrev,
  initialValues,
  group
}) => {

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <StepOneTwoForm
          onPrev={onPrev}
          isSubmitting={isSubmitting}
          group={group}
        />
      )}
    </Formik>
  );
};

export default AddNewAutomationStepOneTwo;
