import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_URL;

const clientService = axios.create({
  baseURL,
  withCredentials: true,
});

clientService.interceptors.request.use((config) => {
  if (!(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  
  // For refresh token API, add both access token and refresh token
  if (config.url === '/api/auth/refresh-token') {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      config.headers['X-Refresh-Token'] = refreshToken;
    }
  }
  
  return config;
});

clientService.interceptors.response.use(
  (response) => {
    // Check if the response contains new tokens and store them
    if (response.data?.accessToken && response.data?.refreshToken) {
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log('response21321321', error)
    if (error.response?.status === 401) {
      if(error.request.responseURL.includes('refresh-token')) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
        return;
      }
      originalRequest._retry = true;

      try {
        const refreshResponse = await clientService.post(
          "/api/auth/refresh-token"
        );

        if (refreshResponse?.data?.accessToken) {
          const { accessToken, refreshToken } = refreshResponse.data;

          localStorage.setItem("accessToken", accessToken);
          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }

          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

          return clientService(originalRequest);
        } else {
          const error = new Error(
            "Failed to refresh token: No access token found"
          );
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/login";
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    } else if(error.response?.status === 500) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default clientService;
