import React, { useState } from "react";
import { FormikValues } from "formik";
import { FormikContext } from "formik";
import AddNewAutomationStepOneForm from "./AddNewAutomationStepOneForm";
import AddNewAutomationStepOneTwo from "./AddNewAutomationStepOneTwo";
import { postAddAutomation, getAutomations } from "../../store/automation";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-toastify";
import AddNewAutomationStepOneThree from "./AddNewAutomationStepOneThree";
import AddNewAutomationStepTargetCollab from "./AddNewAutomationStepTargetCollab";
import { Steps } from 'antd';
import { ApiTwoTone, MessageTwoTone, ContactsTwoTone, AlertTwoTone } from '@ant-design/icons';
import AddNewAutomationStepOneFour from "./AddNewAutomationStepOneFour";
import AddNewAutomationStepMessageImage from "./AddNewAutomationStepMessageImage";
import {
  defaultFormData,
  new_automation_description,
  message_and_taget_collab_description,
  creators_and_timeframe_description,
  follow_up_description,
} from "./defaultFormData";

interface AddNewAutomationFormProps {
  onReturn: () => void;
  automationData?: any;
  group?: any;
  groupGroupDetail?: any;
  step: any;
  setStep: any;
}

const AddNewAutomationForm: React.FC<AddNewAutomationFormProps> = ({
  onReturn,
  automationData,
  group,
  groupGroupDetail = <div />,
  step,
  setStep
}) => {
  const auth = useAppSelector((state) => state.auth);
  const shops = useAppSelector((state) => state.shops);
  const dispatch = useAppDispatch();

  const steps = [
    {
      title: <span style={{ fontWeight: 'bold' }}>New Automation</span>,
      description: new_automation_description,
      icon: <ApiTwoTone />,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Message & Target Collab</span>,
      description: message_and_taget_collab_description,
      icon: <MessageTwoTone />,
    },
    !group && {
      title: <span style={{ fontWeight: 'bold' }}>Creators to Omit/Include</span>,
      description: creators_and_timeframe_description,
      icon: <ContactsTwoTone />,
    },
    !group && {
      title: <span style={{ fontWeight: 'bold' }}>Follow Ups</span>,
      description: follow_up_description,
      icon: <AlertTwoTone />,
    },
  ]

  const defaultAmount = shops.selectedStoreRegion === "UK" ? "1000" : "2500";
  const [formData, setFormData] = useState<FormikValues>(defaultFormData);

  const handleNext = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setStep(step + 1);
  };

  const handleNextwithCommissionRates = (values: FormikValues) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setFormData((prevData) => ({ ...prevData, target_collab_product_comission_rates: initializeCommissionRates(values) }));
    setStep(step + 1);
  };

  const initializeCommissionRates = (values: FormikValues) => {
    if (values.product_id && values.target_collab_product_comission_rates.length === 1 && !values.target_collab_product_comission_rates[0].product_id) {
      return values.product_id.map(productId => ({
        product_id: productId,
        commission_rate: '',
      }));
    }
    return values.target_collab_product_comission_rates;
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  type Result = {
    [key: string]: {
        startTime: string;
        maxCreators: number;
    };
  };

  function processFormSchedule(formValues) {
    const daysOfWeek = [
        'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
    ];

    const result: Result = {};

    daysOfWeek.forEach(day => {
        const timeKey = day.toLowerCase() + '_time';
        const amountKey = day.toLowerCase() + '_amount';

        const startTime = formValues[timeKey];
        let maxCreators = formValues[amountKey];
        const checkboxValue = formValues.schedule_checkboxes[day.toLowerCase()];

        if (checkboxValue) {
            //if (startTime !== "") {
                if (maxCreators === "") {
                    maxCreators = parseInt(defaultAmount, 10);
                } else {
                    maxCreators = parseInt(maxCreators, 10);
                }

                result[day] = {
                    startTime,
                    maxCreators
                };
            //}
        }
    });

    return result;
}



const handleLoginSubmit = async (values, setSubmitting) => {
  try {
      // Set the product_ids [] if the automation type is Message or Message + Image
      let product_ids = values.product_id;
      if (values.automation_type === "Message" || values.automation_type === "Message + Image") {
        product_ids = [];
      }
      // Create the categories list
      let categoriesList = values.category.map(category => ({ Main: category }));
      console.log("categoriesList before sending as a payload", categoriesList);

      // Convert creators_to_omit and creators_to_include arrays to JSON strings
      const creatorsToOmitJson = JSON.stringify(values.creators_to_omit);
      const creatorsToIncludeJson = JSON.stringify(values.creators_to_include);

      // Process the time frame data
      const timeFrame = processFormSchedule(values);
      
      // Adjust content type preference for target collab automation
      let contentType = values.content_type;
      if (!(["No preference", "Shoppable video", "Shoppable LIVE"].includes(values.content_type))) {
        contentType = "No preference";
      }

      // Adjust the target collab message for creator name customization
      let targetCollabMessage = values.message_entry_target_collab;
      if (targetCollabMessage.includes("{creator_name}")) {
        // Replace all occurrences of {creator_name} with {{creators username}}
        targetCollabMessage = targetCollabMessage.replace(/{creator_name}/g, "{{creators username}}");
      }

      // Adjust the target collab name so that it wont have consequtive empty spaces (only one space)
      let targetCollabName = values.target_collab_invitation_name;
      if (targetCollabName !== undefined && targetCollabName !== null && targetCollabName !== "" && targetCollabName.includes("  ")) {
        targetCollabName = targetCollabName.replace(/\s+/g, ' ');
      }
      // Check if final char is empty space, if so remove it
      if (targetCollabName[targetCollabName.length - 1] === ' ') {
        targetCollabName = targetCollabName.slice(0, -1);
      }

      // Create FormData and append the payload data
      const formData = new FormData();
      formData.append('customer_id', auth?.meInfo?.customer_id?.toString() ?? '');
      formData.append('shop_id', shops.selectedStoreId?.toString() ?? '');
      formData.append('shop_name', shops.selectedStoreName ?? '');
      formData.append('automation_name', values.automation_name ?? '');
      formData.append('status', 'inactive');
      formData.append('automation_type', values.automation_type ?? '');
      formData.append('creators_to_omit', creatorsToOmitJson);
      formData.append('creators_to_include', creatorsToIncludeJson);

      if (values.image) {
          formData.append('file', values.image);
          formData.append('file_name', values.image.name);
      }

      // Append config data as JSON string
      const config = {
          DESIRED_PRODUCT_IDS: product_ids,
          Filters: {
              Creators: {
                  "Product Categories": categoriesList,
                  Followers: {
                      "Follower Segments": values.followers
                  }
              },
              Followers: {
                  "Follower Age": values.age,
                  "Follower Gender": values.gender
              },
              Performance: {
                  GMV: values.gmv,
                  "Units Sold": values.units,
                  "Average Views": values.views,
                  "Engagement Rate": values.engagement,
                  GPM: values.video_gpm,
                  "Video Views": values.video_views,
                  "Fulfillment Rate": values.fulfillment_rate
              }
          },
          CREATOR_MESSAGE: values.message_entry,
          SEND_UNREPLIED_FOLLOWUP: values.no_reply_automation,
          DAYS_BEFORE_FOLLOWUP: values.days,
          FOLLOWUP_MESSAGE: values.message_entry_no_reply,
          OFFER_FREE_SAMPLES: values.target_collab_offer_free_samples,
          AUTO_APPROVE: !values.target_collab_manually_approve,
          BASE_INVITATION_NAME: targetCollabName,
          TARGET_COLLAB_MESSAGE: targetCollabMessage,
          EMAIL: values.target_collab_email,
          PHONE_NUMBER: values.target_collab_phone,
          VALID_UNTIL: values.target_collab_valid_until,
          PRODUCTS: values.target_collab_product_comission_rates,
          ONLY_MESSAGE_INCLUDE_LIST: values.only_message_include_list,
          SCHEDULE: timeFrame,
          content_type: contentType,
          TC_DM_MESSAGE: values.tc_dm_message,
      };
      
      // Add follow-up steps to the config
      config.FOLLOW_UP_STEPS = values.followupSteps.map((step, index) => ({
        step_number: index + 1,
        days_after_previous: parseInt(step.days_after_previous, 10),
        message: step.message,
      }));

      formData.append('config', JSON.stringify(config));

      // Optionally append a file if it exists
      if (values.file) {
          formData.append('file', values.file);
          formData.append('file_name', values.file.name);
      }

      console.log("FormData before sending", formData);

      await dispatch(postAddAutomation(formData));
      toast.success("Automation Created!");
      dispatch(getAutomations({ shop_id: shops.selectedStoreId }));
      onReturn();

      setSubmitting(false);
  } catch (error) {
      // Handle failure if needed
      setSubmitting(false);
  }
};


  const renderStepForm = () => {
    switch (step) {
      case 1:
        return (
          <AddNewAutomationStepOneForm
            onNext={handleNextwithCommissionRates}
            initialValues={formData}
            onReturn={onReturn}
            group={group}
          />
        );
      case 2:
        if (formData.automation_type === "Message + Target Collab" ||
          formData.automation_type === "Message + Target Collab + Target Collab Card"
        ) {
          return (
            <AddNewAutomationStepTargetCollab
              onNext={handleNext}
              onPrev={handlePrev}
              initialValues={formData}
              automationType={formData.automation_type}
              group={group}
            />
          );
        } else if (formData.automation_type === "Message + Image") {
          return (
            <AddNewAutomationStepMessageImage
              onNext={handleNext}
              onPrev={handlePrev}
              initialValues={formData}
              group={group}
            />
          );
        } else {
          return (
            <AddNewAutomationStepOneTwo
              onNext={handleNext}
              onPrev={handlePrev}
              initialValues={formData}
              group={group}
            />
          );
        }
      case 3:
        return (
          <AddNewAutomationStepOneThree
            onNext={handleNext}
            onPrev={handlePrev}
            initialValues={formData}
          />
        );
      case 4:
        return (
          <AddNewAutomationStepOneFour
            onPrev={handlePrev}
            initialValues={formData}
            handleLoginSubmit={handleLoginSubmit}
            automationData={automationData}
          />
        )
      default:
        return null;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'INPUT') {
      event.preventDefault();  // Prevent default form submit behavior of the form when the user presses Enter
    }
  };

  return (
    <div className="px-4 mt-8">
      {/*<h3 className="font-semibold text-xl">Create New Automation</h3> */}
      <Steps current={step - 1} className="mt-4 mb-12" items={steps}/>
      {groupGroupDetail}
      <FormikContext.Provider value={{ formData }}>
        <div onKeyDown={handleKeyDown}>{renderStepForm()}</div>
      </FormikContext.Provider>
    </div>
  );
};

export default AddNewAutomationForm;
