import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getAllCustomers, setAdmin } from "../store/admin";

const withAdmin = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Wrapper = (props: P) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);
    const admin = useAppSelector((state) => state.admin);

    useEffect(() => {
      if (auth.isLogin && auth.meInfo.customer_id && admin.customersList.length === 0) {
        if (auth.meInfo.role === "admin") {
          dispatch(setAdmin(true));
          dispatch(getAllCustomers());
        }
      }
    }, [auth.meInfo, auth.loading, admin.isAdmin]);

    return (
      <div>
        <WrappedComponent {...props} />
      </div>
    );
  };

  return Wrapper;
};

export default withAdmin;
